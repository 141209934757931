import React, { useRef, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { css, keyframes } from '@emotion/react';
import { color } from '../defaults/styles/theme';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import { wrapper, IconButton, IconButtonBtn } from '../defaults/styles/elements';
import Link from '../link';
import { Twitter, Linkedin, Youtube, Instagram } from "../defaults/assets/svg/social";
import { Default } from '../defaults/assets/svg/mobilenav';
import mq from '../defaults/styles/mediaquerys';
import { useRegistration } from '../../services/typeformRegistration';
import Flags from '../../data/raw/flags';

import LandscapeLarge from '../../../static/media/hero_animation/hero-landscape-large-2048x1152.mp4';
import LandscapeMedium from '../../../static/media/hero_animation/hero-landscape-medium-1340x754.mp4';
import LandscapeSmall from '../../../static/media/hero_animation/hero-landscape-small-1070x602.mp4';
import PortraitSmall from '../../../static/media/hero_animation/hero-portrait-small.mp4';

const AnimationHeader = () => {

  const videoContainer = useRef(null)
  const container = useRef(null)
  const [fallback, setFallback] = useState(null);
  const [clip, setClip] = useState(null)

  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {sourceInstanceName: {eq: "heroanimation"}, extension: {eq: "png"}}
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    // calculate height of window
    const windowHeight = window.innerHeight
    container.current.style.height = windowHeight + "px"
    // Browser Detection:
    // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser

    // Safari 3.0+ "[object HTMLElementConstructor]" 
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    if ( !isSafari) {

      if (window.innerWidth <= 735 && window.matchMedia("(orientation: landscape)").matches) {
        setFallback(LandscapeSmall)
      } else if (window.innerWidth <= 1068 && window.matchMedia("(orientation: portrait)").matches) {
        setFallback(PortraitSmall)
      } else if (window.innerWidth <= 1068) {
        setFallback(LandscapeMedium)
      } else if (window.innerWidth > 1068) {
        setFallback(LandscapeLarge)
      }

    }

    setTimeout(() => setClip("active"), 1000)
  }, [])

  let files = {}
  data.allFile.edges.forEach(el => {
    if (!files.hasOwnProperty(el.node.name)) {
      files[el.node.name] = el.node.childImageSharp
    }
  })

  const EndFrame = withArtDirection(getImage(files["hero-landscape-large-still"]), [
    {
      media: "(max-width: 735px) and (orientation: portrait)",
      image: getImage(files.["hero-portrait-small-still"]),
    },
    {
      media: "(max-width: 735px)",
      image: getImage(files.["hero-landscape-medium-still"]),
    },
    {
      media: "(max-width: 1068px) and (orientation: portrait)",
      image: getImage(files.["hero-portrait-small-still"]),
    },
    {
      media: "(max-width: 1068px)",
      image: getImage(files["hero-landscape-medium-still"]),
    },
  ])

  const heroClipFinished = () => {
    setClip("animate")
    setTimeout(() => setClip("hidden"), 3000)
  }

  const HeroFooter = () => {
    const socialHandles = [
      {
        name: "LinkedIn",
        url: "https://www.linkedin.com/company/startupautobahn",
        icon: <Linkedin />
      },
      {
        name: "Twitter",
        url: "https://twitter.com/startupautobahn",
        icon: <Twitter />
      },
      {
        name: "Instagram",
        url: "https://www.instagram.com/startupautobahn/",
        icon: <Instagram />
      },
      {
        name: "Youtube",
        url: "https://www.youtube.com/channel/UCRvI70vBDeU4vaU0pz_ylGg",
        icon: <Youtube />
      }
    ]

    return (
      <div css={css`
        display: flex;
        flex-direction: row;
        padding: 1em 0;
        justify-content: center;
        ${mq[0]} {
          justify-content: space-between;
        }
      `}>
        <div>
          <p css={css`
            color: white;
            font-size: .75em;
            opacity: .3;
            line-height: 1.4em;
            text-align: center;
            margin: 0;
            ${mq[0]} {
              text-align: left;
            }
          `}>
            <Link
              to="https://www.plugandplaytechcenter.com/startup-autobahn/"
              css={css`
                color: white;
                text-decoration: none;
              `}
            >
              Plug & Play Germany GmbH © 2021
            </Link>
            <br />for STARTUP AUTOBAHN
          </p>
        </div>
        <div css={css`
          display: none;
          ${mq[0]} {
            display: flex;
          }
          &>*:not(:last-of-type) {
            margin-right: .5em;
          }
        `}>
          {socialHandles.map((el, index) => (
            <IconButton
              key={index}
              url={el.url}
              icon={el.icon}
              css={css`
                height: 2em;
                width: 2em;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                &:before {
                  background: ${color.plue300};
                  border-radius: 3px;
                }
                svg {
                  stroke: none;
                  fill: white;
                  margin-right: 0;
                  height: 50%;
                  width: 50%;
                }
              `}
            />
          ))}
        </div>
      </div>
    )
  }

  const RegistrationButton = () => {
    const {registerButton, toggle} = useRegistration()

    return (
      <>
        <IconButtonBtn
          onClick={toggle}
          name={registerButton.buttonText}
          css={css`
            font-size: 1em;
            letter-spacing: .035em;
            font-weight: 600;
            &:before {
              background: ${color.plue300};
            }
          `}
        />
        {registerButton.errorMessage && <p css={css`
          opacity: .5;
          color: ${color.main_light};
          font-size: .65em;
          line-height: 1.4em;
          margin: 1em 0 0;
          max-width: 15rem;
          text-align: center;
          a {
            color: ${color.main_light}; 
          }
        `}>Something went wrong. For further details please click <a href="#registerErrorMessage">here</a>.</p>}
      </>
    )
    
  }
  
  const RearviewButton = () => {

    return (
      <IconButton
        to="/rearview"
        name="Rewatch EXPO 10"
        css={css`
          font-size: 1em;
          letter-spacing: .035em;
          font-weight: 600;
          &:before {
            background: ${color.plue300};
          }
        `}
      />
    )
    
  }

  const LiveButton = () => {
    const blink = keyframes`
      from {
        opacity: 1.0;
      }
      50% {
        opacity: .1;
      }
      to {
        opacity: 1.0;
      }
    ` 
    return (
      <IconButton 
        to={Flags.settings.live.destination}
        icon={<Default />}
        name={`Join the livestream now`}
        css={css`
          font-size: 1em;
          letter-spacing: .035em;
          font-weight: 600;
          color: ${color.richBlack};
          svg {
            animation: ${blink} 2s ease-in-out infinite;
            stroke: none;
            fill: ${color.richBlack};
          }
          &:before {
            background: ${color.plue_rec};
          }
        `}
      />
    )
  }



  return (
    <section 
      css={css`
        background: ${color.richBlack};
        height: 100vh;
        width: 100%;
        display: grid;
        position: relative;
      `}
      ref={container}
     >
      {!(clip === "hidden" || clip === null) && <video
        ref={videoContainer}
        src={fallback}
        onEnded={() => heroClipFinished()}
        muted
        playsInline 
        autoPlay
        css={css`
          width: 100%;
          height: 100%;
          object-fit: cover;
          grid-area: 1 / 1;
          opacity: ${clip !== "animate" ? 1 : 0};
          transition: opacity 3s;
          z-index: 2;
          overflow: hidden;
        `}
      >
        <source
          src={PortraitSmall}
          type="video/mp4"
          media="(max-width: 735px) and (orientation: portrait)"
        />
        <source
          src={LandscapeSmall}
          type="video/mp4"
          media="(max-width: 735px)"
        />
        <source
          src={PortraitSmall}
          type="video/mp4"
          media="(max-width: 1068px) and (orientation: portrait)"
        />
        <source
          src={LandscapeMedium}
          type="video/mp4"
          media="(max-width: 1068px)"
        />
        <source
          src={LandscapeLarge}
          type="video/mp4"
        />
      </video> }
      <div css={css`
        ${wrapper};
        grid-area: 1 / 1;
        z-index: 3;
        position: relative;
        bottom: 0;
        left: 0;
        align-self: flex-end;
        width: 100%;
      `}>
        <HeroFooter />
      </div>
      {!(clip === "active" || clip === null) && (
        <>
          <div css={css`
            ${wrapper},
            height: 100%;
            grid-area: 1 / 1;
            z-index: 1;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            width: 100%;
          `}>
            <div css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin-bottom: 9em;
            `}>
              <h1 css={css`
                color: white;
                text-align: center;
                line-height: 1.14285714em;
                letter-spacing: 0.025em;
                font-size: 2em;
                font-size: clamp(2rem, 2vw + 1.4rem, 2.625rem);
                margin-bottom: 1em;
                ${mq[2]} {
                  font-size: 2.625em;
                  font-size: clamp(2rem, 2vw + 1.4rem, 2.625rem);
                }
              `}>
                { Flags.features.live ? <span>Come in, we’re live.</span> : <span>Come in, we’re open.<br />As Always.</span> }
              </h1>

              {Flags.features.live ? <LiveButton /> : Flags.features.main_registration ? <RegistrationButton /> : Flags.features.rearview ? <RearviewButton /> : null}
            </div>
          </div>
          {/* <img src={getSrc(data.allFile.edges[0].node)} css={css`
            width: 100%;
            height: 100%;
            object-fit: cover;
            //grid-area: 1 / 1 / 2 / 2;
            position: absolute;
            top: 0;
            left: 0;
          `} /> */}
          <GatsbyImage 
            loading="eager"
            placeholder="tracedSVG"
            alt="endframe of hero animation"
            image={EndFrame}
            backgroundColor="black"
            css={css`
              width: 100%;
              height: 100%;
              object-fit: cover;
              grid-area: 1 / 1 / 2 / 2;
            `} 
          />
        </>
      )}
    </section>
  )
}

export default AnimationHeader;
